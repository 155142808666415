<template>
<v-container style="height:100%">
  <!-- <v-alert v-if="!Object.keys(filteredRetailers).length" type="info">
    <p>No data to display.</p>
  </v-alert> -->
  <v-card ref="mapContainer" style="height: 100%; display:flex;flex-direction:column;align-items:center;justify-content:center;">
    <v-progress-circular
      v-if="!filteredRetailers.length"
      indeterminate
      color="primary"
    ></v-progress-circular>
  <l-map v-else ref="map" style="height: 100%;z-index:1" :zoom="zoom" :center="center">
    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
      <l-feature-group @ready="onReady" ref="features">
      <template v-for='(retailer, index) of filteredRetailers'>
          <l-marker @click="scroll(index)" :key='index' v-if="retailer.latitude" :lat-lng="[retailer.latitude, retailer.longitude]" :z-index-offset = "selected === index ? 1000 : null">
          <l-icon
            :icon-size="selected === index ? [40, 40] : [20,20]"
            :icon-url="selected === index ? greenIcon : redIcon"
             :icon-anchor="[20, 40]"
          />
          <l-tooltip  :options="{ interactive: true }">{{retailer.retailer}}</l-tooltip>
        </l-marker>
      </template>
      </l-feature-group>
  </l-map>
  </v-card>
  </v-container>
</template>

<script>
import { LMap, LTileLayer, LFeatureGroup, LMarker, LPopup, LIcon, LTooltip } from 'vue2-leaflet'
import { mapGetters } from 'vuex'
export default {
  props: {
  },
  components: {
    LMap,
    LTileLayer,
    LFeatureGroup,
    LMarker,
    LPopup,
    LIcon,
    LTooltip
  },
  data () {
    return {
      greenIcon: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-green.png',
      redIcon: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
      mapOverlay: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 12,
      center: [55.94736446069031, -3.1921626077463103],
      markerLatLng: [51.504, -0.159],
      imageUrl: process.env.VUE_APP_IMG_URL,
      iconSize: 40
    }
  },
  methods: {
    scroll (item) {
      this.$emit('scrollToSelected', item)
    },
    getMapTop () {
      return this.$refs.mapContainer && this.$refs.mapContainer.$el.getBoundingClientRect().top
    },
    onResize () {
      this.$refs.map[0].mapObject._onResize()
    },
    onReady () {
      const bnds = this.$refs.features && this.$refs.features.mapObject.getBounds()
      this.$refs.map.fitBounds(bnds)
    }
  },
  watch: {
    async retailers (newRetailers, oldRetailers) {
      const bnds = await this.$refs.features && this.$refs.features.mapObject.getBounds()
      bnds && this.$refs.map && this.$refs.map.fitBounds(bnds)
    }
  },
  computed: {
    dynamicSize () {
      return [this.iconSize, this.iconSize * 1.15]
    },
    dynamicAnchor () {
      return [this.iconSize / 2, this.iconSize * 1.15]
    },
    ...mapGetters({
      filteredRetailers: 'retailers/filteredRetailersByGeo',
      selected: 'retailers/selected'
      }
    )
  }
}
</script>
<style lang="scss">
  .cluster-marker {
    border: 1px solid red;
    background-color: yellow;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
