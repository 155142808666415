<template>
<v-main app>
    <v-navigation-drawer
      class="d-flex flex-column fill-height"
      clipped
      app
      :permanent="$vuetify.breakpoint.mdAndUp"
      :style="$vuetify.breakpoint.mdAndUp ? 'z-index: 1;' : 'z-index: 999999;'"
    >
      <v-card classs="fixed">
      <v-card-text>
        <v-text-field v-model="postCode" label="Post Code" hide-details dense outlined clearable @click:clear="clearSearch"
          @input="updateSearch">
        </v-text-field>
        </v-card-text>
        <v-card-subtitle class="pt-0">
          Showing {{filteredRetailers.length}} retailers
        </v-card-subtitle>
      </v-card>
      <v-list class="fill-height" two-line>
        <v-list-item-group
          @change="handleSelect"
          active-class="blue--text"
        >
          <v-list-item style="border-bottom: 1px solid grey" v-for="(item, index) in filteredRetailers" :id="'item-' + index" :key="item.title">
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="item.retailer"></v-list-item-title>
                <v-list-item-subtitle style="white-space: wrap">{{getAddress(item)}}</v-list-item-subtitle>
                <router-link :to="'/retailers/retailer/' + item.id">Details</router-link>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <div class="d-flex flex-column fill-height">
    <router-view></router-view>
    </div>
</v-main>
</template>

<script>

import Retailers from '@/views/Public/Retailers/Retailers.vue'
import Retailer from '@/components/RetailerDetail'
import RetailersAPI from '@/api/retailers'
import formatAddress from '@/utilities/formatRetailerAddress'
import axios from 'axios'
import { mapGetters } from 'vuex'
import isPointWithinRadius from 'geolib/es/isPointWithinRadius'

export default {
  metaInfo: {
    title: "Resellers | Where to buy our products"
  },
  components: {
    Retailers,
    Retailer
  },
  data () {
    return {
      loaded: false,
      postCode: null
    }
  },
  computed: {
    retailerDetail() {
        return this.$router.currentRoute.params.id;
    },
    ...mapGetters({
      retailers: 'retailers/retailers',
      filteredRetailers: 'retailers/filteredRetailersByGeo',
      selected: 'retailers/selected'
      }
    )
  },
  methods: {
    scrollTo (item) {
      this.retailerDetail = true
      document.getElementById('item-' + item).scrollIntoView()
    },
    getAddress (item) {
      return formatAddress(item)
    },
    showResellerDetails (item) {
      this.retailerDetail = true
    },
    closeResellerDetails (item) {
      this.retailerDetail = false
    },
    handleSelect (item) {
      this.$store.dispatch('retailers/setSelected', item)
    },
    async clearSearch () {
      this.$store.dispatch('retailers/setGeoFiltered', this.retailers)
    },
    async updateSearch (item) {
      if (item === '') {
        this.$store.dispatch('retailers/setGeoFiltered', this.retailers)
        return true
      }
      try {
        const geo = await axios.get(`https://api.postcodes.io/outcodes/${item}`)
        const centre = { latitude: geo.data.result.latitude, longitude: geo.data.result.longitude }
        const filtered = this.retailers.filter(retailer => {
          try {
            const nearby = isPointWithinRadius(
              { latitude: retailer.latitude, longitude: retailer.longitude },
              centre,
              10000
            )
            return nearby ? retailer : null
          } catch (e) {  }
        })
        this.$store.dispatch('retailers/setGeoFiltered', filtered)
      } catch (e) {
        // console.log('nf', e)
      }
    }
  },
}
</script>
<style lang="scss">
.v-list {
  height: calc(100% - 120px);
  overflow-y:auto
}
</style>
